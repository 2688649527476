import mapboxgl from "mapbox-gl";
import mapboxSdk from "@mapbox/mapbox-sdk/services/geocoding";
mapboxgl.accessToken =
	"pk.eyJ1IjoicGNhLW1pcnJvciIsImEiOiJja21kZGlieGIya2xpMnBrbnc5bnJuNjRqIn0.vCtWItCADjSGY5b6GmF13Q";

window.locations = function () {
	return {
		initZoom: 16,
		initCenter: [-3.5263815853474716, 50.72478685233888],
		showMap: false,

		init() {
			// Map
			const mapContainer = this.$refs.map;
			const map = new mapboxgl.Map({
				container: mapContainer,
				style: "mapbox://styles/mapbox/outdoors-v11",
				center: this.initCenter,
				zoom: this.initZoom,
				scrollZoom: false,
			});
			map.addControl(new mapboxgl.NavigationControl());

			// resize map to fill container
			map.on("idle", () => {
				map.resize();
				this.showMap = true;
			});

			// Markers
			// Helper
			var forEach = function (array, callback, scope) {
				for (var i = 0; i < array.length; i++) {
					callback.call(scope, i, array[i]); // passes back stuff we need
				}
			};
			// Markers
			const markers = this.$el.querySelectorAll(".marker");
			forEach(markers, function (index, el) {
				const mapboxClient = mapboxSdk({
					accessToken: mapboxgl.accessToken,
				});
				const address = el.dataset.address;
				mapboxClient
					.forwardGeocode({
						query: address,
						autocomplete: false,
						limit: 1,
					})
					.send()
					.then(function (response) {
						if (
							response &&
							response.body &&
							response.body.features &&
							response.body.features.length
						) {
							const feature = response.body.features[0];
							new mapboxgl.Marker(el)
								.setLngLat(feature.center)
								.addTo(map);
						}
					});
			});
		},
	};
};
