window.homeTop = function () {
	return {
		readMore: false,
		videoOrientation: "landscape",
		init() {
			// add resize event listener
			this.changeVideo();
			window.addEventListener("resize", () => {
				this.changeVideo();
			});
		},
		changeVideo() {
			this.videoOrientation =
				window.innerWidth > window.innerHeight
					? "landscape"
					: "portrait";
			console.log(this.videoOrientation);
		},
		toggleReadMore() {
			this.readMore = !this.readMore;
		},
	};
};
