export function autoplayVideo() {
	return {
		show: false,
		init() {
			setTimeout(() => {
				this.playVideo();
				setTimeout(() => {
					this.show = true;
				}, 250);
			}, 250);
		},
		playVideo() {
			this.$refs.video.play();
		},
	};
}
