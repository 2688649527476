import Plyr from "plyr";

export function video() {
	const player = new Plyr(this.$refs.video, {
		controls: [
			"play-large",
			"play", // Play/pause playback
			"progress", // The progress bar and scrubber for playback and buffering
			"current-time", // The current time of playback
			"duration", // The full duration of the media
			"mute", // Toggle mute
			"volume", // Volume control
			"fullscreen", // Toggle fullscreen
		],
	});

	return {
		play() {
			player.play();
		},
		pause() {
			player.pause();
		},
	};
}
