export function accessibilityButton() {
	return {
		showButton: false,
		checkVisibility() {
			let y = document.querySelector("#main").getBoundingClientRect().top;
			if (y < window.innerHeight / 2) {
				this.showButton = true;
			} else {
				this.showButton = false;
			}
		},
	};
}
